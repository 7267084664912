<template>
  <el-form-item :label="label" :prop="prop" :rules="rules" >
    <el-input v-model="model" clearable placeholder="请输入"></el-input>
  </el-form-item>
</template>

<script>
export default {
  name: "input-box",
  props: {
    value: {
      type: String,
      default: () => {
        return ""
      }
    },
    label: {
      type: String,
      default: () => {
        return ""
      }
    },
    is_rules: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    prop: {
      type: String,
      default: () => {
        return ""
      }
    }
  },
  data() {
    return {
      model: ""
    }
  },
  computed: {
    rules() {
      let arr = [];
      if(this.is_rules) {
        arr = [
          { required: true, message: '此项为必填项', trigger: 'blur' },
        ]
      }
      return arr;
    }
  },
  watch: {
    value: {
      handler(newVal) {
        this.model = newVal;
      },
      immediate: true,
      deep: true
    },
    model(newVal) {
      this.$emit("input",newVal);
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style scoped lang="scss" >

</style>
