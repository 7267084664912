<template>
  <el-form ref="formRef"
           label-width="100px"
           :model="dynamicFormData"
           label-position="top"
           hide-required-asterisk
           @submit.native.prevent
           size="small" >
    <div class="content">

      <template v-for="(item,index) in dynamicFormData.domains" >

        <template v-if="item.type === '0'" >
        <!--单选-->
          <radioBox
              :key="index"
              v-model="dynamicFormData.domains[index].answerId"
              :prop="`domains.${index}.answerId`"
              :is_rules="item.necessary"
              :label="item.questionContent"
              :localdata="item.answers" />
        </template>

        <template v-if="item.type === '1'"  >
          <!--多选-->
          <checkboxBoxBox
              :key="index"
              v-model="dynamicFormData.domains[index].answerId"
              :prop="`domains.${index}.answerId`"
              :is_rules="item.necessary"
              :label="item.questionContent"
              :localdata="item.answers"  />
        </template>

        <template v-if="item.type === '2'" >
        <!--输入框-->
          <inputBox
              :key="index"
              v-model="dynamicFormData.domains[index].answerStr"
              :prop="`domains.${index}.answerStr`"
              :is_rules="item.necessary"
              :label="item.questionContent" />
        </template>

        <template v-if="item.type === '4'" >
          <div class="form-title" :key="index" >
            <p>{{ item.questionContent }}</p>
          </div>
        </template>

        <template v-if="is_child(item)">
          <form-box v-model="dynamicFormData.domains[index].childQuestions" :key="`childQuestions${index}`" />
        </template>

      </template>

    </div>
  </el-form>
</template>

<script>
import inputBox from "@/components/TestKit/components/input-box";
import radioBox from "@/components/TestKit/components/radio-box";
import checkboxBoxBox from "@/components/TestKit/components/checkbox-box-box";
export default {
  name: "form-box",
  components: {
    inputBox,
    radioBox,
    checkboxBoxBox
  },
  props: {
    value: {
      type: Object,
      default:() => {
        return {
          domains: []
        };
      }
    },
  },
  data() {
    return{

    }
  },
  computed: {
    dynamicFormData: {
      get() {
        return this.value;
      },
      set(v) {
        console.log(v);
        this.$emit("input",v);
      },
    },
    is_child() {
      return function(item) {
        let flag = false;

        if(item.type === '0') {
          let is_linkage = (item.answers || []).filter(item => item.isLinkage);

          if(is_linkage.length > 0 && item.childQuestions.domains && item.childQuestions.domains.length > 0) {
            if(item.answerId === is_linkage[0].id) {
              // console.log(item);
              flag = true;
            }
          }

        }

        return flag;
      }
    },
    answerId_str() {
      return function(v) {
        let str;

        if(v instanceof Array) {
          str = v.join(',');
        }else {
          str = v;
        }

        return str;
      }
    }
  },
  watch:{
    value: {
      handler(newVal) {
        this.$emit("input",newVal);
      },
      immediate: true,
      deep: true
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    save() {
      // eslint-disable-next-line no-unused-vars
      let arr = (this.value.domains || []).map((item,index) => {
        let childQuestions_domains = [];

        if(this.is_child(item)) {
          childQuestions_domains = item.childQuestions.domains || [];
        }else {
          childQuestions_domains = []
        }

        return {
          answerId: this.answerId_str(item.answerId),
          answerStr: item.answerStr,
          questionId: item.id,
          questionnaireType: item.questionnaireType,
          // eslint-disable-next-line no-unused-vars
          childQuestionnaireResults: childQuestions_domains.map((item_,index) => {
            return {
              answerId: this.answerId_str(item_.answerId),
              answerStr: item_.answerStr,
              questionId: item_.id,
              questionnaireType: item_.questionnaireType,
            }
          })
        }
      })
      return arr;
    },
    onSubmit() {
      return new Promise((resolve, reject) => {
        this.$refs.formRef.validate((valid) => {
          if(valid) {
            let arr;

            if(this.$refs["formBoxRef_"] && this.$refs["formBoxRef_"].length > 0) {

              // eslint-disable-next-line no-unused-vars
              let submit_arr = this.$refs["formBoxRef_"].map((item,index) => {
                return item.onSubmit()
              })


              Promise.all(submit_arr)
                  .then(res => {
                    console.log(res);
                    console.log(this.value)
                    arr = this.save();
                    resolve(arr);
                  })
                  .catch(err => {
                    reject(err);
                  })
            }else {
              // eslint-disable-next-line no-unused-vars
              arr = (this.value.domains || []).map((item,index) => {
                return {
                  answerId: this.answerId_str(item.answerId),
                  answerStr: item.answerStr,
                  questionId: item.id,
                  questionnaireType: item.questionnaireType,
                  childQuestionnaireResults: []
                }
              })
              resolve(arr);
            }
          }else {
            console.log(`验证未通过`);
            reject(this.value);
          }
        })
      })
    }
  }
}
</script>

<style scoped lang="scss" >
.content{
  width: 100%;
}
.form-title{
  display: flex;
  align-items: center;
  background: #1A7CF2;
  color: #FFFFFF;
  padding: 12px 20px;
  margin-bottom: 22px;
  border-radius: 5px;
}
</style>
